// src/screens/Math/PercentageCalculatorScreen.js
import React, { useState } from 'react';
import { formatNumber } from '../../utils/mathFunctions';

const CalculatorCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
    <div className="flex items-center mb-4 text-violet-600">
      {icon}
      <h2 className="text-lg font-semibold ml-2">{title}</h2>
    </div>
    {children}
  </div>
);

const InputField = ({ label, value, onChange, placeholder, suffix }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <div className="relative rounded-lg">
      <input
        type="number"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="block w-full px-4 py-2.5 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-colors"
      />
      {suffix && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500">
          {suffix}
        </div>
      )}
    </div>
  </div>
);

const ResultDisplay = ({ label, value }) => (
  <div className="bg-gray-50 rounded-lg p-4 mt-4">
    <div className="text-sm text-gray-600 mb-1">{label}</div>
    <div className="text-2xl font-semibold text-violet-600">{value}</div>
  </div>
);

const PercentageCalculatorScreen = () => {
  // Basic Percentage
  const [basicNumber, setBasicNumber] = useState('');
  const [basicPercent, setBasicPercent] = useState('');

  // Percentage Change
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');

  // Percentage Of
  const [partValue, setPartValue] = useState('');
  const [wholeValue, setWholeValue] = useState('');

  // Ratio to Percentage
  const [numerator, setNumerator] = useState('');
  const [denominator, setDenominator] = useState('');

  // Calculate Basic Percentage
  const calculateBasicPercentage = () => {
    if (!basicNumber || !basicPercent) return '0';
    return formatNumber((parseFloat(basicNumber) * parseFloat(basicPercent)) / 100);
  };

  // Calculate Percentage Change
  const calculatePercentageChange = () => {
    if (!oldValue || !newValue) return '0';
    const change = ((parseFloat(newValue) - parseFloat(oldValue)) / parseFloat(oldValue)) * 100;
    return formatNumber(change);
  };

  // Calculate Percentage Of Total
  const calculatePercentageOf = () => {
    if (!partValue || !wholeValue) return '0';
    return formatNumber((parseFloat(partValue) / parseFloat(wholeValue)) * 100);
  };

  // Calculate Ratio to Percentage
  const calculateRatioPercentage = () => {
    if (!numerator || !denominator) return '0';
    return formatNumber((parseFloat(numerator) / parseFloat(denominator)) * 100);
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gray-50 py-8 px-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Percentage Calculator</h1>
        
        <div className="grid gap-6 md:grid-cols-2">
          {/* Basic Percentage */}
          <CalculatorCard 
            title="Basic Percentage"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>}
          >
            <InputField
              label="Number"
              value={basicNumber}
              onChange={(e) => setBasicNumber(e.target.value)}
              placeholder="Enter number"
            />
            <InputField
              label="Percentage"
              value={basicPercent}
              onChange={(e) => setBasicPercent(e.target.value)}
              placeholder="Enter percentage"
              suffix="%"
            />
            <ResultDisplay
              label="Result"
              value={calculateBasicPercentage()}
            />
          </CalculatorCard>

          {/* Percentage Change */}
          <CalculatorCard 
            title="Percentage Change"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
            </svg>}
          >
            <InputField
              label="Original Value"
              value={oldValue}
              onChange={(e) => setOldValue(e.target.value)}
              placeholder="Enter original value"
            />
            <InputField
              label="New Value"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder="Enter new value"
            />
            <ResultDisplay
              label="Percentage Change"
              value={`${calculatePercentageChange()}%`}
            />
          </CalculatorCard>

          {/* Percentage Of Total */}
          <CalculatorCard 
            title="Percentage Of Total"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>}
          >
            <InputField
              label="Part Value"
              value={partValue}
              onChange={(e) => setPartValue(e.target.value)}
              placeholder="Enter part value"
            />
            <InputField
              label="Total Value"
              value={wholeValue}
              onChange={(e) => setWholeValue(e.target.value)}
              placeholder="Enter total value"
            />
            <ResultDisplay
              label="Percentage of Total"
              value={`${calculatePercentageOf()}%`}
            />
          </CalculatorCard>

          {/* Ratio to Percentage */}
          <CalculatorCard 
            title="Ratio to Percentage"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>}
          >
            <InputField
              label="Numerator"
              value={numerator}
              onChange={(e) => setNumerator(e.target.value)}
              placeholder="Enter numerator"
            />
            <InputField
              label="Denominator"
              value={denominator}
              onChange={(e) => setDenominator(e.target.value)}
              placeholder="Enter denominator"
            />
            <ResultDisplay
              label="Ratio as Percentage"
              value={`${calculateRatioPercentage()}%`}
            />
          </CalculatorCard>
        </div>
      </div>
    </div>
  );
};

export default PercentageCalculatorScreen;
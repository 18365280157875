// src/components/QuadraticGraph.js
import React, { useState, useRef, useEffect } from 'react';

const ZoomControl = ({ onZoomIn, onZoomOut, onReset }) => (
  <div className="absolute bottom-4 right-4 flex space-x-2">
    <button
      onClick={onZoomIn}
      className="p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 text-gray-700"
      aria-label="Zoom in"
    >
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
      </svg>
    </button>
    <button
      onClick={onZoomOut}
      className="p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 text-gray-700"
      aria-label="Zoom out"
    >
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
      </svg>
    </button>
    <button
      onClick={onReset}
      className="p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 text-gray-700"
      aria-label="Reset view"
    >
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </button>
  </div>
);

const GraphInfo = ({ hoveredPoint, xScale, yScale }) => (
  <div className="absolute top-4 left-4 bg-white/90 backdrop-blur-sm rounded-lg shadow-md p-3 text-sm">
    {hoveredPoint ? (
      <div>
        <div className="font-medium text-violet-600">{hoveredPoint.label}</div>
        <div className="text-gray-600">
          x: {hoveredPoint.x.toFixed(2)}<br />
          y: {hoveredPoint.y.toFixed(2)}
        </div>
      </div>
    ) : (
      <div className="text-gray-600">Hover over points for coordinates</div>
    )}
  </div>
);

const QuadraticGraph = ({ a, b, c, width: initialWidth = 400, height: initialHeight = 300 }) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [dimensions, setDimensions] = useState({ width: initialWidth, height: initialHeight });

  // Responsive sizing
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const containerWidth = container.clientWidth;
        const containerHeight = Math.min(containerWidth * 0.75, initialHeight);
        setDimensions({ width: containerWidth, height: containerHeight });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [initialWidth, initialHeight]);

  const drawGraph = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { width, height } = dimensions;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);
    ctx.save();

    // Apply transformations
    ctx.translate(width/2 + offset.x, height/2 + offset.y);
    ctx.scale(20 * scale, -20 * scale);

    // Draw grid
    const gridSize = Math.ceil(width/(40 * scale));
    ctx.strokeStyle = '#e5e7eb';
    ctx.lineWidth = 1/(20 * scale);

    for (let x = -gridSize; x <= gridSize; x++) {
      ctx.beginPath();
      ctx.moveTo(x, -height/(40 * scale));
      ctx.lineTo(x, height/(40 * scale));
      ctx.stroke();
    }

    for (let y = -gridSize; y <= gridSize; y++) {
      ctx.beginPath();
      ctx.moveTo(-width/(40 * scale), y);
      ctx.lineTo(width/(40 * scale), y);
      ctx.stroke();
    }

    // Draw axes
    ctx.strokeStyle = '#6b7280';
    ctx.lineWidth = 2/(20 * scale);
    ctx.beginPath();
    ctx.moveTo(-width/(40 * scale), 0);
    ctx.lineTo(width/(40 * scale), 0);
    ctx.moveTo(0, -height/(40 * scale));
    ctx.lineTo(0, height/(40 * scale));
    ctx.stroke();

    // Draw parabola
    ctx.beginPath();
    ctx.strokeStyle = '#8b5cf6';
    ctx.lineWidth = 3/(20 * scale);

    const points = [];
    for (let x = -width/(40 * scale); x <= width/(40 * scale); x += 0.1) {
      const y = a * x * x + b * x + c;
      points.push([x, y]);
    }

    ctx.moveTo(points[0][0], points[0][1]);
    points.forEach(([x, y]) => ctx.lineTo(x, y));
    ctx.stroke();

    // Draw important points
    const vertex = { x: -b/(2*a), y: a*Math.pow(-b/(2*a), 2) + b*(-b/(2*a)) + c };
    const yIntercept = { x: 0, y: c };
    const discriminant = b*b - 4*a*c;
    const roots = [];

    if (discriminant >= 0) {
      const x1 = (-b + Math.sqrt(discriminant))/(2*a);
      const x2 = (-b - Math.sqrt(discriminant))/(2*a);
      roots.push({ x: x1, y: 0 }, { x: x2, y: 0 });
    }

    const drawPoint = (point, label) => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 5/(20 * scale), 0, 2 * Math.PI);
      ctx.fillStyle = hoveredPoint?.label === label ? '#7c3aed' : '#8b5cf6';
      ctx.fill();
    };

    drawPoint(vertex, 'Vertex');
    drawPoint(yIntercept, 'Y-intercept');
    roots.forEach((root, i) => drawPoint(root, `Root ${i + 1}`));

    ctx.restore();
  };

  useEffect(() => {
    drawGraph();
  }, [a, b, c, scale, offset, dimensions, hoveredPoint]);

  // Pan handlers
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setOffset({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Zoom handlers
  const handleZoomIn = () => setScale(s => Math.min(s * 1.2, 5));
  const handleZoomOut = () => setScale(s => Math.max(s / 1.2, 0.5));
  const handleReset = () => {
    setScale(1);
    setOffset({ x: 0, y: 0 });
  };

  // Touch handlers for mobile
  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      handleMouseDown({ clientX: touch.clientX, clientY: touch.clientY });
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      handleMouseMove({ clientX: touch.clientX, clientY: touch.clientY });
    }
  };

  return (
    <div 
      ref={containerRef}
      className="relative w-full touch-none"
      style={{ height: dimensions.height }}
    >
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleMouseUp}
        className="border border-gray-200 rounded-lg bg-white shadow-inner cursor-move"
      />
      <ZoomControl 
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onReset={handleReset}
      />
      <GraphInfo
        hoveredPoint={hoveredPoint}
        xScale={scale}
        yScale={scale}
      />
    </div>
  );
};

export default QuadraticGraph;
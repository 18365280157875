import React, { useState, useEffect } from 'react';
import CalculatorButton from '../../components/CalculatorButton';
import CalculatorDisplay from '../../components/CalculatorDisplay';
import { calculate, formatNumber } from '../../utils/mathFunctions';
import { useAnalytics } from '../../hooks/useAnalytics';

const BasicCalculatorScreen = () => {
  const [display, setDisplay] = useState('0');
  const [equation, setEquation] = useState('');
  const [prevNumber, setPrevNumber] = useState(null);
  const [operation, setOperation] = useState(null);
  const [resetDisplay, setResetDisplay] = useState(false);
  const [calculationStartTime, setCalculationStartTime] = useState(null);

  const { 
    trackCalculation, 
    trackError, 
    trackInteraction,
    trackFeatureUsage 
  } = useAnalytics();

  // Track initial calculator load
  useEffect(() => {
    trackFeatureUsage('basic_calculator', 'open', {
      initial_display: display
    });
  }, []);

  const handleNumber = (num) => {
    try {
      trackInteraction({
        eventType: 'number_input',
        elementId: `number_${num}`,
        metadata: {
          current_display: display,
          input_value: num
        }
      });

      if (resetDisplay || display === '0') {
        setDisplay(num);
        setResetDisplay(false);
      } else {
        setDisplay(display + num);
      }
    } catch (error) {
      trackError('basic', 'input_error', error.message, {
        input_value: num,
        current_display: display
      });
    }
  };

  const handleOperation = (op) => {
    try {
      trackInteraction({
        eventType: 'operation_selected',
        elementId: `operation_${op}`,
        metadata: {
          operation: op,
          current_value: display
        }
      });

      setCalculationStartTime(performance.now());
      setOperation(op);
      setPrevNumber(parseFloat(display));
      setResetDisplay(true);
      setEquation(`${display} ${op}`);
    } catch (error) {
      trackError('basic', 'operation_error', error.message, {
        operation: op,
        current_display: display
      });
    }
  };

  const handleEqual = () => {
    if (!operation || !prevNumber) {
      trackError('basic', 'invalid_calculation', 'Missing operation or previous number', {
        operation,
        prevNumber,
        current_display: display
      });
      return;
    }

    try {
      const secondNumber = parseFloat(display);
      const result = calculate(prevNumber, display, operation);
      const calculationTime = performance.now() - calculationStartTime;

      // Track the calculation
      trackCalculation({
        calculatorType: 'basic',
        operation,
        inputValue1: prevNumber,
        inputValue2: secondNumber,
        result: result,
        calculationTime,
        equation: `${equation} ${display} =`
      });

      setDisplay(formatNumber(result));
      setEquation(`${equation} ${display} =`);
      setOperation(null);
      setPrevNumber(null);
      setResetDisplay(true);
    } catch (error) {
      trackError('basic', 'calculation_error', error.message, {
        operation,
        prevNumber,
        currentNumber: display,
        equation
      });
    }
  };

  const handleClear = () => {
    trackInteraction({
      eventType: 'clear',
      elementId: 'clear_button',
      metadata: {
        previous_display: display,
        previous_equation: equation
      }
    });

    setDisplay('0');
    setEquation('');
    setPrevNumber(null);
    setOperation(null);
    setResetDisplay(false);
  };

  const handleSpecialOperation = (type) => {
    try {
      const currentValue = parseFloat(display);
      let result;

      switch (type) {
        case 'negate':
          result = currentValue * -1;
          break;
        case 'percentage':
          result = currentValue / 100;
          break;
        default:
          return;
      }

      trackCalculation({
        calculatorType: 'basic',
        operation: type,
        inputValue1: currentValue,
        result: result,
        calculationTime: 0
      });

      setDisplay(result.toString());
    } catch (error) {
      trackError('basic', 'special_operation_error', error.message, {
        operation_type: type,
        input_value: display
      });
    }
  };

  const buttons = [
    { label: 'C', variant: 'function', onClick: handleClear },
    { label: '±', variant: 'function', onClick: () => handleSpecialOperation('negate') },
    { label: '%', variant: 'function', onClick: () => handleSpecialOperation('percentage') },
    { label: '÷', variant: 'operator', onClick: () => handleOperation('÷') },
    { label: '7', onClick: () => handleNumber('7') },
    { label: '8', onClick: () => handleNumber('8') },
    { label: '9', onClick: () => handleNumber('9') },
    { label: '×', variant: 'operator', onClick: () => handleOperation('×') },
    { label: '4', onClick: () => handleNumber('4') },
    { label: '5', onClick: () => handleNumber('5') },
    { label: '6', onClick: () => handleNumber('6') },
    { label: '-', variant: 'operator', onClick: () => handleOperation('-') },
    { label: '1', onClick: () => handleNumber('1') },
    { label: '2', onClick: () => handleNumber('2') },
    { label: '3', onClick: () => handleNumber('3') },
    { label: '+', variant: 'operator', onClick: () => handleOperation('+') },
    { label: '0', onClick: () => handleNumber('0'), fullWidth: true },
    { label: '.', onClick: () => !display.includes('.') && handleNumber('.') },
    { label: '=', variant: 'operator', onClick: handleEqual }
  ];

  return (
    <div className="flex flex-col justify-center items-center min-h-[calc(100vh-4rem)] bg-gray-50 px-4">
      <div className="w-full max-w-md">
        <div className="bg-white rounded-3xl shadow-xl p-8">
          <h1 className="text-2xl font-semibold text-gray-800 mb-6">Basic Calculator</h1>
          <div className="bg-gray-50 p-6 rounded-2xl mb-6">
            <div className="text-gray-500 text-right h-6 text-sm mb-2 transition-all">
              {equation}
            </div>
            <div className="text-5xl font-light text-right h-14 overflow-hidden transition-all">
              {display}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            {buttons.map((btn, index) => (
              <CalculatorButton
                key={index}
                label={btn.label}
                onClick={btn.onClick}
                variant={btn.variant}
                fullWidth={btn.fullWidth}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicCalculatorScreen;
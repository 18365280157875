// src/hooks/useAnalytics.js
import { useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { analytics } from '../firebaseConfig';
import { 
  logEvent, 
  setUserProperties, 
  setAnalyticsCollectionEnabled 
} from 'firebase/analytics';

/**
 * @typedef {Object} CalculationMetadata
 * @property {string} calculatorType - Type of calculator (basic, scientific, etc.)
 * @property {string} operation - The mathematical operation performed
 * @property {number} inputValue1 - First input value
 * @property {number} inputValue2 - Second input value (if applicable)
 * @property {number} result - Calculation result
 * @property {number} calculationTime - Time taken to perform calculation in ms
 */

/**
 * @typedef {Object} UserInteractionEvent
 * @property {string} eventType - Type of interaction (click, input, etc.)
 * @property {string} elementId - ID of the interacted element
 * @property {Object} metadata - Additional event metadata
 */

export const useAnalytics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const startTimeRef = useRef(Date.now());
  const previousPathRef = useRef(location.pathname);
  const sessionStartTime = useRef(Date.now());
  const interactionCount = useRef(0);

  // Initialize analytics tracking
  useEffect(() => {
    // Enable analytics collection
    setAnalyticsCollectionEnabled(analytics, true);

    // Track session start
    logEvent(analytics, 'session_start', {
      timestamp: new Date().toISOString(),
      initial_page: location.pathname,
      referrer: document.referrer || 'direct'
    });

    // Cleanup on session end
    return () => {
      const sessionDuration = Date.now() - sessionStartTime.current;
      logEvent(analytics, 'session_end', {
        session_duration: sessionDuration,
        total_interactions: interactionCount.current,
        last_page: location.pathname
      });
    };
  }, []);

  // Track page views and time spent
  useEffect(() => {
    const currentPath = location.pathname;
    const screenName = getScreenName(currentPath);
    
    // Log page view
    logEvent(analytics, 'screen_view', {
      screen_name: screenName,
      screen_path: currentPath,
      timestamp: new Date().toISOString(),
      from_path: previousPathRef.current
    });

    // Calculate time spent on previous screen
    const timeSpent = Date.now() - startTimeRef.current;
    if (previousPathRef.current !== currentPath) {
      logEvent(analytics, 'screen_exit', {
        screen_name: getScreenName(previousPathRef.current),
        screen_path: previousPathRef.current,
        time_spent: timeSpent,
        next_screen: screenName
      });
    }

    // Reset timer and update refs
    startTimeRef.current = Date.now();
    previousPathRef.current = currentPath;

    // Cleanup function to log final screen time
    return () => {
      const finalTimeSpent = Date.now() - startTimeRef.current;
      logEvent(analytics, 'screen_time', {
        screen_name: screenName,
        screen_path: currentPath,
        time_spent: finalTimeSpent
      });
    };
  }, [location]);

  /**
   * Get readable screen name from path
   * @param {string} path - Current path
   * @returns {string} Formatted screen name
   */
  const getScreenName = (path) => {
    const parts = path.split('/').filter(Boolean);
    if (parts.length < 2) return 'Home';
    
    const category = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    const type = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    return `${category} - ${type} Calculator`;
  };

  /**
   * Track calculator usage
   * @param {CalculationMetadata} metadata - Calculation metadata
   */
  const trackCalculation = useCallback((metadata) => {
    const startTime = performance.now();
    
    logEvent(analytics, 'calculation_performed', {
      ...metadata,
      timestamp: new Date().toISOString(),
      calculation_time: performance.now() - startTime,
      screen_name: getScreenName(location.pathname)
    });
    
    interactionCount.current += 1;
  }, [location]);

  /**
   * Track calculation errors
   * @param {string} calculatorType - Type of calculator
   * @param {string} errorType - Type of error
   * @param {string} errorMessage - Error message
   * @param {Object} metadata - Additional error metadata
   */
  const trackError = useCallback((calculatorType, errorType, errorMessage, metadata = {}) => {
    logEvent(analytics, 'calculation_error', {
      calculator_type: calculatorType,
      error_type: errorType,
      error_message: errorMessage,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...metadata
    });
  }, [location]);

  /**
   * Track user interactions
   * @param {UserInteractionEvent} event - Interaction event details
   */
  const trackInteraction = useCallback((event) => {
    logEvent(analytics, 'user_interaction', {
      ...event,
      screen_path: location.pathname,
      timestamp: new Date().toISOString()
    });
    
    interactionCount.current += 1;
  }, [location]);

  /**
   * Track feature usage
   * @param {string} featureId - ID of the feature
   * @param {string} action - Action performed
   * @param {Object} metadata - Additional feature metadata
   */
  const trackFeatureUsage = useCallback((featureId, action, metadata = {}) => {
    logEvent(analytics, 'feature_usage', {
      feature_id: featureId,
      action,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...metadata
    });
  }, [location]);

  /**
   * Track performance metrics
   * @param {string} metricName - Name of the metric
   * @param {number} value - Metric value
   * @param {Object} metadata - Additional metric metadata
   */
  const trackPerformance = useCallback((metricName, value, metadata = {}) => {
    logEvent(analytics, 'performance_metric', {
      metric_name: metricName,
      value,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...metadata
    });
  }, [location]);

  /**
   * Track user preferences
   * @param {string} preferenceType - Type of preference
   * @param {*} value - Preference value
   */
  const trackPreference = useCallback((preferenceType, value) => {
    setUserProperties(analytics, {
      [preferenceType]: value
    });
    
    logEvent(analytics, 'preference_update', {
      preference_type: preferenceType,
      value,
      timestamp: new Date().toISOString()
    });
  }, []);

  return {
    trackCalculation,
    trackError,
    trackInteraction,
    trackFeatureUsage,
    trackPerformance,
    trackPreference
  };
};
// src/utils/mathFunctions.js
export const formatNumber = (num) => {
    if (num === '' || num === undefined || num === null) return '0';
    if (num === 'Error' || num === Infinity || num === -Infinity) return 'Error';
    if (typeof num === 'string' && num.includes('e')) return num;
    
    const formatted = parseFloat(num);
    if (isNaN(formatted)) return '0';
    
    // Handle very small or large numbers
    if (Math.abs(formatted) < 1e-7 || Math.abs(formatted) > 1e10) {
      return formatted.toExponential(7);
    }
    
    // Limit decimal places for regular numbers
    return Number(formatted.toPrecision(12)).toString();
  };
  
  export const calculate = (prev, current, operation) => {
    const a = parseFloat(prev);
    const b = parseFloat(current);
    
    switch (operation) {
      case '+': return a + b;
      case '-': return a - b;
      case '×': return a * b;
      case '÷': return b !== 0 ? a / b : 'Error';
      case 'mod': return b !== 0 ? a % b : 'Error';
      case 'yˣ': return Math.pow(a, b);
      default: return current;
    }
  };
  
  export const calculateScientific = (num, func, isInverse, isRadians) => {
    const value = parseFloat(num);
    
    try {
      switch (func) {
        // Trigonometric Functions
        case 'sin':
          return isInverse 
            ? Math.asin(value) * (isRadians ? 1 : 180/Math.PI)
            : Math.sin(value * (isRadians ? 1 : Math.PI/180));
        
        case 'cos':
          return isInverse 
            ? Math.acos(value) * (isRadians ? 1 : 180/Math.PI)
            : Math.cos(value * (isRadians ? 1 : Math.PI/180));
        
        case 'tan':
          return isInverse 
            ? Math.atan(value) * (isRadians ? 1 : 180/Math.PI)
            : Math.tan(value * (isRadians ? 1 : Math.PI/180));
  
        // Hyperbolic Functions
        case 'sinh':
          return isInverse ? Math.asinh(value) : Math.sinh(value);
        
        case 'cosh':
          return isInverse ? Math.acosh(value) : Math.cosh(value);
        
        case 'tanh':
          return isInverse ? Math.atanh(value) : Math.tanh(value);
  
        // Logarithmic Functions
        case 'log':
          return isInverse ? Math.pow(10, value) : Math.log10(value);
        
        case 'ln':
          return isInverse ? Math.exp(value) : Math.log(value);
  
        // Power and Root Functions
        case 'sqrt':
          return isInverse ? value * value : Math.sqrt(value);
        
        case 'cbrt':
          return isInverse ? Math.pow(value, 3) : Math.cbrt(value);
        
        case 'x²':
          return Math.pow(value, 2);
        
        case 'x³':
          return Math.pow(value, 3);
        
        case '1/x':
          return value !== 0 ? 1 / value : 'Error';
  
        // Rounding Functions
        case 'floor':
          return Math.floor(value);
        
        case 'ceil':
          return Math.ceil(value);
        
        case 'round':
          return Math.round(value);
  
        // Other Mathematical Functions
        case 'abs':
          return Math.abs(value);
        
        case 'fact':
          return factorial(Math.round(value));
        
        case 'exp':
          return isInverse ? Math.log(value) : Math.exp(value);
  
        // Constants
        case 'π':
          return Math.PI;
        
        case 'e':
          return Math.E;
  
        // Random and Additional Functions
        case 'rand':
          return Math.random();
        
        case 'sign':
          return Math.sign(value);
        
        case 'trunc':
          return Math.trunc(value);
  
        default:
          return value;
      }
    } catch (error) {
      return 'Error';
    }
  };
  
  export const factorial = (n) => {
    if (n < 0) return 'Error';
    if (!Number.isInteger(n)) return 'Error';
    if (n > 170) return 'Error'; // Prevent overflow
    if (n === 0 || n === 1) return 1;
    
    try {
      let result = 1;
      for (let i = 2; i <= n; i++) {
        result *= i;
        if (!isFinite(result)) throw new Error('Overflow');
      }
      return result;
    } catch {
      return 'Error';
    }
  };
  
  export const toScientificNotation = (num) => {
    if (num === 'Error') return 'Error';
    const value = parseFloat(num);
    if (isNaN(value)) return 'Error';
    if (value === 0) return '0';
    
    const absValue = Math.abs(value);
    if (absValue < 1e-7 || absValue >= 1e10) {
      return value.toExponential(7);
    }
    return value.toString();
  };
  
  export const MATHEMATICAL_CONSTANTS = {
    PI: Math.PI,
    E: Math.E,
    GOLDEN_RATIO: (1 + Math.sqrt(5)) / 2,
    SQRT2: Math.SQRT2,
    SQRT1_2: Math.SQRT1_2,
    LN2: Math.LN2,
    LN10: Math.LN10,
    LOG2E: Math.LOG2E,
    LOG10E: Math.LOG10E,
    TAU: Math.PI * 2,
    PHI: (1 + Math.sqrt(5)) / 2,
  };
  
  // Memory operations
  export class CalculatorMemory {
    constructor() {
      this.memory = 0;
      this.history = [];
    }
  
    recall() {
      return this.memory;
    }
  
    add(value) {
      const parsedValue = parseFloat(value) || 0;
      this.memory += parsedValue;
      this.history.push({ operation: 'M+', value: parsedValue, result: this.memory });
      return this.memory;
    }
  
    subtract(value) {
      const parsedValue = parseFloat(value) || 0;
      this.memory -= parsedValue;
      this.history.push({ operation: 'M-', value: parsedValue, result: this.memory });
      return this.memory;
    }
  
    clear() {
      this.memory = 0;
      this.history = [];
      return this.memory;
    }
  
    store(value) {
      const parsedValue = parseFloat(value) || 0;
      this.memory = parsedValue;
      this.history.push({ operation: 'MS', value: parsedValue, result: this.memory });
      return this.memory;
    }
  
    getHistory() {
      return this.history;
    }
  }
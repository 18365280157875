import React from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

export const CalculatorCard = ({ title, children, icon }) => (
    <div className="bg-white rounded-2xl shadow-lg p-4 md:p-6 hover:shadow-xl transition-shadow">
      <div className="flex items-center mb-4 text-violet-600">
        {icon}
        <h2 className="text-lg font-semibold ml-2">{title}</h2>
      </div>
      {children}
    </div>
  );
  
  export const CoefficientInput = ({ label, value, onChange, coefficient }) => (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <div className="relative">
        <input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full px-3 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
          placeholder={`Enter ${coefficient}`}
        />
        <div className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-400">
          {coefficient}
        </div>
      </div>
    </div>
  );
  
 
  
  export const QuadraticFormula = ({ a, b, c }) => (
    <div className="flex flex-col items-center justify-center my-4 space-y-3">
      <span className="text-sm text-gray-600">Current Equation:</span>
      <BlockMath>{`${a}x^2 ${b >= 0 ? '+' : ''}${b}x ${c >= 0 ? '+' : ''}${c} = 0`}</BlockMath>
    </div>
  );
  
  export const StepBox = ({ title, children, latex, isResult }) => (
    <div className={`p-4 rounded-lg ${
      isResult ? 'bg-violet-50 border border-violet-200' : 'bg-gray-50'
    } transition-all hover:shadow-sm`}>
      <h3 className="text-sm font-medium text-gray-600 mb-2">{title}</h3>
      <div className="text-base md:text-lg">
        {latex ? (
          <BlockMath>{latex}</BlockMath>
        ) : (
          children
        )}
      </div>
    </div>
  );
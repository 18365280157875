// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDv9_qztXlU1hlgXx4C2C2LQER8FDrDJFY",
    authDomain: "realcalculator-197e6.firebaseapp.com",
    projectId: "realcalculator-197e6",
    storageBucket: "realcalculator-197e6.firebasestorage.app",
    messagingSenderId: "82277536107",
    appId: "1:82277536107:web:83f7089fec631eab487c2e",
    measurementId: "G-SW01R1W931"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };
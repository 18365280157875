// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import BasicCalculatorScreen from './screens/Math/BasicCalculatorScreen';
import ScientificCalculatorScreen from './screens/Math/ScientificCalculatorScreen';
import PercentageCalculatorScreen from './screens/Math/PercentageCalculatorScreen';
import FractionCalculatorScreen from './screens/Math/FractionCalculatorScreen';
import QuadraticCalculatorScreen from './screens/Math/QuadraticCalculatorScreen';
import MortgageCalculatorScreen from './screens/Financial/MortgageCalculatorScreen';
import LoanCalculatorScreen from './screens/Financial/LoanCalculatorScreen';

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Navigate to="/math/basic" replace />} />
            <Route path="/math">
              <Route index element={<Navigate to="/math/basic" replace />} />
              <Route path="basic" element={<BasicCalculatorScreen />} />
              <Route path="scientific" element={<ScientificCalculatorScreen />} />
              <Route path="percentage" element={<PercentageCalculatorScreen />} />
              <Route path="fraction" element={<FractionCalculatorScreen />} />
              <Route path="quadratic" element={<QuadraticCalculatorScreen />} />


            </Route>
            <Route path="/financial">
              <Route index element={<Navigate to="/financial/mortgage" replace />} />
              <Route path="mortgage" element={<MortgageCalculatorScreen />} />
              <Route path="loan" element={<LoanCalculatorScreen />} />


              {/* Add financial calculator routes as needed */}
            </Route>
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default App;
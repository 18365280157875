import React from 'react';

const CalculatorDisplay = ({ equation, result }) => {
  return (
    <div className="bg-gray-50 p-6 rounded-2xl mb-4">
      <div className="text-gray-500 text-right h-6 text-sm mb-1">
        {equation}
      </div>
      <div className="text-4xl font-light text-right h-12 overflow-hidden">
        {result}
      </div>
    </div>
  );
};

export default CalculatorDisplay;
// src/screens/Math/QuadraticCalculatorScreen.js
import React, { useState, useEffect } from 'react';
import QuadraticGraph from '../../components/QuadraticGraph'; // Import the enhanced graph component
import {
    CalculatorCard,
    CoefficientInput,
    StepBox,
    QuadraticFormula
  } from '../../components/CalculatorComponents';
  import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const QuadraticCalculatorScreen = () => {
    const [a, setA] = useState('1');
    const [b, setB] = useState('0');
    const [c, setC] = useState('0');
    const [solutions, setSolutions] = useState(null);
    const [discriminant, setDiscriminant] = useState(null);
    const [steps, setSteps] = useState([]);
    const [error, setError] = useState(null);
  
    const calculateQuadratic = () => {
        try {
          setError(null);
          const coefA = parseFloat(a) || 0;
          const coefB = parseFloat(b) || 0;
          const coefC = parseFloat(c) || 0;
    
          if (coefA === 0) {
            setError("Coefficient 'a' cannot be zero for a quadratic equation.");
            return;
          }
    
          const disc = coefB * coefB - 4 * coefA * coefC;
          setDiscriminant(disc);
    
          const newSteps = [
            {
              title: "The Quadratic Formula",
              latex: `x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}`
            },
            {
              title: "Substitute Values",
              latex: `x = \\frac{-(${coefB}) \\pm \\sqrt{(${coefB})^2 - 4(${coefA})(${coefC})}}{2(${coefA})}`
            },
            {
              title: "Calculate Discriminant",
              latex: `\\begin{aligned}
                \\text{Discriminant} &= b^2 - 4ac \\\\
                &= (${coefB})^2 - 4(${coefA})(${coefC}) \\\\
                &= ${disc}
              \\end{aligned}`
            }
          ];
    
          if (disc > 0) {
            const x1 = (-coefB + Math.sqrt(disc)) / (2 * coefA);
            const x2 = (-coefB - Math.sqrt(disc)) / (2 * coefA);
            setSolutions({ x1, x2 });
            newSteps.push({
              title: "Two Real Solutions",
              latex: `\\begin{aligned}
                x_1 &= ${x1.toFixed(4)} \\\\
                x_2 &= ${x2.toFixed(4)}
              \\end{aligned}`
            });
          } else if (disc === 0) {
            const x = -coefB / (2 * coefA);
            setSolutions({ x1: x, x2: x });
            newSteps.push({
              title: "One Real Solution (Double Root)",
              latex: `x = ${x.toFixed(4)}`
            });
          } else {
            const realPart = -coefB / (2 * coefA);
            const imagPart = Math.sqrt(Math.abs(disc)) / (2 * coefA);
            setSolutions({
              x1: `${realPart.toFixed(4)} + ${imagPart.toFixed(4)}i`,
              x2: `${realPart.toFixed(4)} - ${imagPart.toFixed(4)}i`
            });
            newSteps.push({
              title: "Two Complex Solutions",
              latex: `\\begin{aligned}
                x_1 &= ${realPart.toFixed(4)} + ${imagPart.toFixed(4)}i \\\\
                x_2 &= ${realPart.toFixed(4)} - ${imagPart.toFixed(4)}i
              \\end{aligned}`
            });
          }
    
          // Add vertex form
          const vertexX = -coefB / (2 * coefA);
          const vertexY = coefA * vertexX * vertexX + coefB * vertexX + coefC;
          newSteps.push({
            title: "Vertex Form",
            latex: `\\begin{aligned}
              y &= ${coefA}(x - ${vertexX.toFixed(4)})^2 + ${vertexY.toFixed(4)} \\\\
              \\text{Vertex} &= (${vertexX.toFixed(4)}, ${vertexY.toFixed(4)})
            \\end{aligned}`
          });
    
          setSteps(newSteps);
        } catch (err) {
          setError("Invalid input values. Please check your coefficients.");
        }
      };
  
    useEffect(() => {
      calculateQuadratic();
    }, [a, b, c]);
  
    return (
        <div className="min-h-[calc(100vh-4rem)] bg-gray-50 p-4 md:py-8 md:px-6">
          <div className="max-w-6xl mx-auto">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-xl md:text-2xl font-bold text-gray-800">
                Quadratic Formula Calculator
              </h1>
              <div className="text-sm text-gray-600">
                <InlineMath>{`f(x) = ax^2 + bx + c`}</InlineMath>
              </div>
            </div>
    
            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
                {error}
              </div>
            )}
    
            <div className="grid gap-4 md:gap-6 lg:grid-cols-2">
              <div className="space-y-4 md:space-y-6">
                <CalculatorCard
                  title="Enter Coefficients"
                  icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>}
                >
                  <div className="text-center mb-4">
                    <BlockMath>{`ax^2 + bx + c = 0`}</BlockMath>
                  </div>
                  <div className="grid grid-cols-3 gap-3 md:gap-4">
                    <CoefficientInput label="Coefficient a" value={a} onChange={setA} coefficient="a" />
                    <CoefficientInput label="Coefficient b" value={b} onChange={setB} coefficient="b" />
                    <CoefficientInput label="Coefficient c" value={c} onChange={setC} coefficient="c" />
                  </div>
                  <QuadraticFormula a={a} b={b} c={c} />
                </CalculatorCard>
  
              <CalculatorCard
                title="Graph Visualization"
                icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                </svg>}
              >
                <div className="rounded-xl overflow-hidden bg-gray-50 p-2 md:p-4">
                  <QuadraticGraph 
                    a={parseFloat(a)} 
                    b={parseFloat(b)} 
                    c={parseFloat(c)}
                  />
                </div>
                <div className="mt-3 space-y-1 text-xs md:text-sm text-gray-600">
                  <p>• Pan: Click and drag or touch and drag</p>
                  <p>• Zoom: Use controls or pinch gesture</p>
                  <p>• Reset: Click the reset button to restore default view</p>
                  <p>• Points: Hover/tap to see coordinates</p>
                </div>
              </CalculatorCard>
            </div>
  
            <CalculatorCard
            title="Solutions"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>}
          >
            <div className="space-y-4">
              {steps.map((step, index) => (
                <StepBox 
                  key={index}
                  title={step.title}
                  latex={step.latex}
                  isResult={index === steps.length - 1}
                />
              ))}
              
              {discriminant !== null && (
                <div className="mt-4 p-4 rounded-lg bg-gray-50">
                  <h3 className="text-sm font-medium text-gray-600 mb-2">Nature of Roots</h3>
                  <div className="space-y-2">
                    <div className="text-sm md:text-base text-gray-700">
                      {discriminant > 0 && (
                        <div>
                          <InlineMath>{`\\Delta > 0`}</InlineMath>: Two distinct real roots
                        </div>
                      )}
                      {discriminant === 0 && (
                        <div>
                          <InlineMath>{`\\Delta = 0`}</InlineMath>: One real root (repeated)
                        </div>
                      )}
                      {discriminant < 0 && (
                        <div>
                          <InlineMath>{`\\Delta < 0`}</InlineMath>: Two complex conjugate roots
                        </div>
                      )}
                    </div>
                    <BlockMath>{`\\Delta = ${discriminant}`}</BlockMath>
                  </div>
                </div>
              )}
            </div>
          </CalculatorCard>
        </div>
      </div>
    </div>
  );
};

export default QuadraticCalculatorScreen;
// src/components/FinancialCalculatorComponents.js
import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export const CalculatorCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-2xl shadow-lg p-4 md:p-6 hover:shadow-xl transition-shadow">
    <div className="flex items-center mb-4 text-violet-600">
      {icon}
      <h2 className="text-lg font-semibold ml-2">{title}</h2>
    </div>
    {children}
  </div>
);

export const InputField = ({ label, value, onChange, prefix, suffix, type = "text", step, info }) => (
  <div className="space-y-1">
    <label className="flex justify-between items-center">
      <span className="block text-sm font-medium text-gray-700">{label}</span>
      {info && (
        <span className="text-xs text-gray-500">{info}</span>
      )}
    </label>
    <div className="relative rounded-md shadow-sm">
      {prefix && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{prefix}</span>
        </div>
      )}
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`block w-full rounded-md border-gray-300 focus:ring-violet-500 focus:border-violet-500 sm:text-sm
          ${prefix ? 'pl-7' : 'pl-3'} ${suffix ? 'pr-12' : 'pr-3'} py-2
          bg-gray-50 border transition-colors`}
        step={step}
      />
      {suffix && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{suffix}</span>
        </div>
      )}
    </div>
  </div>
);

export const ResultCard = ({ label, value, color = "violet", subtext }) => (
  <div className={`bg-${color}-50 rounded-lg p-4 border border-${color}-100`}>
    <div className="text-sm text-gray-600 mb-1">{label}</div>
    <div className={`text-xl font-semibold text-${color}-600`}>{value}</div>
    {subtext && (
      <div className="text-xs text-gray-500 mt-1">{subtext}</div>
    )}
  </div>
);

export const AmortizationTable = ({ schedule, type = 'loan' }) => {
    const formatPeriod = (row) => {
      if (type === 'mortgage') {
        const year = Math.floor((row.month - 1) / 12) + 1;
        return `Year ${year}`;
      } else {
        const year = Math.floor((row.period - 1) / 12) + 1;
        const month = row.period % 12 || 12;
        return `Year ${year}, Month ${month}`;
      }
    };
  
    return (
      <div className="overflow-x-auto -mx-4 md:mx-0">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Period
                </th>
                <th className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Payment
                </th>
                <th className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Principal
                </th>
                <th className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Interest
                </th>
                <th className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Balance
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {schedule.map((row, index) => (
                <tr 
                  key={index} 
                  className={index % 2 === 0 ? 'bg-white hover:bg-gray-50' : 'bg-gray-50 hover:bg-gray-100'}
                >
                  <td className="px-3 md:px-6 py-3 text-xs md:text-sm text-gray-900">
                    {formatPeriod(row)}
                  </td>
                  <td className="px-3 md:px-6 py-3 text-xs md:text-sm text-gray-900">
                    ${row.payment.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </td>
                  <td className="px-3 md:px-6 py-3 text-xs md:text-sm text-gray-900">
                    ${row.principal.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </td>
                  <td className="px-3 md:px-6 py-3 text-xs md:text-sm text-gray-900">
                    ${row.interest.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </td>
                  <td className="px-3 md:px-6 py-3 text-xs md:text-sm text-gray-900">
                    ${row.balance.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export const PaymentChart = ({ data = [] }) => {
    if (!data || data.length === 0) {
      return (
        <div className="h-[300px] flex items-center justify-center text-gray-500">
          Loading chart data...
        </div>
      );
    }
  
    const chartLines = [
      { dataKey: 'principal', name: 'Principal', stroke: '#8b5cf6' },
      { dataKey: 'interest', name: 'Interest', stroke: '#f43f5e' }
    ];
  
    return (
      <div className="h-[300px] -mx-6 md:mx-0">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
            <XAxis
              dataKey="year"
              tickFormatter={(value) => `Year ${value}`}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              tick={{ fontSize: 12 }}
              tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
            />
            <Tooltip
              formatter={(value) => `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
              labelFormatter={(value) => `Year ${value}`}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: '0.5rem',
                border: '1px solid #e5e7eb',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Legend
              verticalAlign="top"
              height={36}
            />
            {chartLines.map((line, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={line.dataKey}
                name={line.name}
                stroke={line.stroke}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4, strokeWidth: 0 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

// Common Icons
export const CalculatorIcons = {
  Money: ({ className = "w-5 h-5" }) => (
    <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  ),
  
  Chart: ({ className = "w-5 h-5" }) => (
    <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
    </svg>
  ),
  
  Table: ({ className = "w-5 h-5" }) => (
    <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
    </svg>
  )
};

// Common Utilities
export const formatCurrency = (value, maximumFractionDigits = 0) => {
  return value.toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  });
};

export const formatPercentage = (value, decimals = 1) => {
  return `${value.toFixed(decimals)}%`;
};
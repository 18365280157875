// src/screens/Financial/LoanCalculatorScreen.js
import React, { useState, useEffect } from 'react';
import {
  CalculatorCard,
  InputField,
  ResultCard,
  AmortizationTable,
  PaymentChart,
  CalculatorIcons,
  formatCurrency,
  formatPercentage
} from '../../components/FinancialCalculatorComponents';
import { Collapsible } from '../../components/Collapsible';

const LoanCalculatorScreen = () => {
  // State management
  const [loanAmount, setLoanAmount] = useState('10000');
  const [interestRate, setInterestRate] = useState('5');
  const [loanTerm, setLoanTerm] = useState('3');
  const [paymentFrequency, setPaymentFrequency] = useState('monthly');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [chartData, setChartData] = useState([]);

  const frequencies = {
    weekly: { periods: 52, label: 'Weekly' },
    biweekly: { periods: 26, label: 'Bi-Weekly' },
    monthly: { periods: 12, label: 'Monthly' },
    quarterly: { periods: 4, label: 'Quarterly' },
    annually: { periods: 1, label: 'Annually' }
  };

  const calculateLoan = () => {
    const principal = parseFloat(loanAmount);
    const periodsPerYear = frequencies[paymentFrequency].periods;
    const totalPeriods = parseFloat(loanTerm) * periodsPerYear;
    const periodicRate = (parseFloat(interestRate) / 100) / periodsPerYear;

    if (principal > 0 && periodicRate > 0 && totalPeriods > 0) {
      // Calculate payment amount
      const x = Math.pow(1 + periodicRate, totalPeriods);
      const payment = (principal * x * periodicRate) / (x - 1);

      setPaymentAmount(payment);
      setTotalPayment(payment * totalPeriods);
      setTotalInterest((payment * totalPeriods) - principal);

      // Calculate amortization schedule
      let balance = principal;
      const schedule = [];
      const periodData = [];
      let periodPrincipal = 0;
      let periodInterest = 0;

      for (let i = 1; i <= totalPeriods; i++) {
        const interest = balance * periodicRate;
        const principalPaid = payment - interest;
        balance = Math.max(0, balance - principalPaid);

        periodPrincipal += principalPaid;
        periodInterest += interest;

        // Create periodic data points (e.g., monthly data)
        if (i % periodsPerYear === 0 || i === totalPeriods) {
          periodData.push({
            period: Math.ceil(i / periodsPerYear),
            principal: periodPrincipal,
            interest: periodInterest
          });
          periodPrincipal = 0;
          periodInterest = 0;
        }

        schedule.push({
          period: i,
          payment,
          principal: principalPaid,
          interest,
          balance
        });
      }

      setAmortizationSchedule(schedule);
      setChartData(periodData);
    }
  };

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, interestRate, loanTerm, paymentFrequency]);

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gray-50 py-4 md:py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-6">
          Loan Calculator
        </h1>

        <div className="grid gap-4 md:gap-6 lg:grid-cols-3">
          {/* Input Section */}
          <div className="lg:col-span-1 space-y-4 md:space-y-6">
            <CalculatorCard title="Loan Details" icon={<CalculatorIcons.Money />}>
              <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
                <InputField
                  label="Loan Amount"
                  value={loanAmount}
                  onChange={setLoanAmount}
                  prefix="$"
                  type="number"
                  step="100"
                />
                <InputField
                  label="Interest Rate"
                  value={interestRate}
                  onChange={setInterestRate}
                  suffix="%"
                  type="number"
                  step="0.1"
                />
                <InputField
                  label="Loan Term"
                  value={loanTerm}
                  onChange={setLoanTerm}
                  suffix="years"
                  type="number"
                  step="1"
                />
                <div className="col-span-2 md:col-span-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Payment Frequency
                  </label>
                  <select
                    value={paymentFrequency}
                    onChange={(e) => setPaymentFrequency(e.target.value)}
                    className="w-full rounded-md border-gray-300 bg-gray-50 focus:ring-violet-500 focus:border-violet-500"
                  >
                    {Object.entries(frequencies).map(([key, { label }]) => (
                      <option key={key} value={key}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </CalculatorCard>

            <CalculatorCard title="Payment Summary" icon={<CalculatorIcons.Money />}>
              <div className="space-y-4">
                <ResultCard
                  label={`${frequencies[paymentFrequency].label} Payment`}
                  value={formatCurrency(paymentAmount)}
                  description="Principal + Interest"
                />
                <ResultCard
                  label="Total Interest"
                  value={formatCurrency(totalInterest)}
                  color="rose"
                  description={`${formatPercentage((totalInterest / totalPayment) * 100)} of total payment`}
                />
                <ResultCard
                  label="Total Payment"
                  value={formatCurrency(totalPayment)}
                  color="emerald"
                  description={`Over ${loanTerm} years`}
                />
              </div>
            </CalculatorCard>
          </div>

          {/* Chart and Table Section */}
          <div className="lg:col-span-2 space-y-4 md:space-y-6">
            <CalculatorCard 
              title="Payment Breakdown" 
              icon={<CalculatorIcons.Chart />}
            >
              <div className="space-y-4">
                <div className="text-sm text-gray-500">
                  See how your payments are split between principal and interest
                </div>
                <PaymentChart data={chartData} />
              </div>
            </CalculatorCard>

            <CalculatorCard 
              title="Payment Schedule" 
              icon={<CalculatorIcons.Table />}
            >
              <Collapsible 
                title={`View ${frequencies[paymentFrequency].label} Payment Schedule`}
                className="border border-gray-200 rounded-lg"
              >
                <div className="overflow-hidden">
                  <div className="text-sm text-gray-500 mb-4">
                    Complete breakdown of your loan payments
                  </div>
                  <AmortizationTable 
                        schedule={amortizationSchedule.filter((_, i) => 
                            i % frequencies[paymentFrequency].periods === 0
                        )}
                        paymentFrequency={paymentFrequency}
                        />
                </div>
              </Collapsible>

              {/* Payment Summary Cards */}
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="text-sm font-medium text-gray-700 mb-2">First Payment</div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-600">
                      Principal: {formatCurrency(amortizationSchedule[0]?.principal || 0)}
                    </div>
                    <div className="text-sm text-gray-600">
                      Interest: {formatCurrency(amortizationSchedule[0]?.interest || 0)}
                    </div>
                  </div>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="text-sm font-medium text-gray-700 mb-2">Last Payment</div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-600">
                      Principal: {formatCurrency(amortizationSchedule[amortizationSchedule.length - 1]?.principal || 0)}
                    </div>
                    <div className="text-sm text-gray-600">
                      Interest: {formatCurrency(amortizationSchedule[amortizationSchedule.length - 1]?.interest || 0)}
                    </div>
                  </div>
                </div>
              </div>
            </CalculatorCard>
          </div>
        </div>

        {/* About Section */}
        <div className="mt-6">
          <Collapsible 
            title="About this calculator" 
            className="bg-blue-50 rounded-lg border border-blue-100"
          >
            <div className="px-4 pb-4">
              <p className="text-sm text-blue-600">
                This loan calculator helps you estimate your loan payments with different payment frequencies. 
                It provides a detailed amortization schedule and breaks down your payments between principal and interest.
              </p>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white bg-opacity-50 rounded p-3">
                  <h4 className="text-sm font-medium text-blue-800 mb-2">Features:</h4>
                  <ul className="text-sm text-blue-600 space-y-1">
                    <li>• Multiple payment frequency options</li>
                    <li>• Detailed payment breakdown</li>
                    <li>• Visual payment distribution</li>
                    <li>• Complete amortization schedule</li>
                  </ul>
                </div>
                <div className="bg-white bg-opacity-50 rounded p-3">
                  <h4 className="text-sm font-medium text-blue-800 mb-2">Notes:</h4>
                  <ul className="text-sm text-blue-600 space-y-1">
                    <li>• Interest is compounded based on payment frequency</li>
                    <li>• All payments include principal and interest</li>
                    <li>• Extra payments are not included</li>
                    <li>• Fees and charges are not included</li>
                  </ul>
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default LoanCalculatorScreen;
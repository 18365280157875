// src/screens/Math/FractionCalculatorScreen.js
import React, { useState, useEffect } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';

const CalculatorCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
    <div className="flex items-center mb-4 text-violet-600">
      {icon}
      <h2 className="text-lg font-semibold ml-2">{title}</h2>
    </div>
    {children}
  </div>
);

const FractionInput = ({ label, value, onChange, trackInput = () => {} }) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div className="flex items-center space-x-2">
      <input
        type="number"
        value={value.numerator}
        onChange={(e) => {
          const newValue = { ...value, numerator: e.target.value };
          onChange(newValue);
          trackInput(newValue, 'numerator');
        }}
        className="w-20 px-3 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
        placeholder="0"
      />
      <div className="w-8 h-0.5 bg-gray-400"></div>
      <input
        type="number"
        value={value.denominator}
        onChange={(e) => {
          const newValue = { ...value, denominator: e.target.value };
          onChange(newValue);
          trackInput(newValue, 'denominator');
        }}
        className="w-20 px-3 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
        placeholder="1"
      />
    </div>
  </div>
);

const ResultDisplay = ({ label, fraction, decimal, children }) => (
  <div className="bg-gray-50 rounded-lg p-4 mt-4">
    <div className="text-sm text-gray-600 mb-2">{label}</div>
    {fraction ? (
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-2xl font-semibold text-violet-600">{fraction.numerator}</span>
          <div className="w-6 h-0.5 bg-violet-600"></div>
          <span className="text-2xl font-semibold text-violet-600">{fraction.denominator}</span>
        </div>
        {decimal && <div className="text-lg text-gray-500">≈ {decimal}</div>}
      </div>
    ) : (
      <div className="text-2xl font-semibold text-violet-600">{children}</div>
    )}
  </div>
);

const ComparisonDisplay = ({ fraction1, fraction2, onCompare }) => {
  const value1 = fraction1.numerator / fraction1.denominator;
  const value2 = fraction2.numerator / fraction2.denominator;
  let comparison;
  let symbol;

  if (value1 > value2) {
    comparison = "is greater than";
    symbol = ">";
  } else if (value1 < value2) {
    comparison = "is less than";
    symbol = "<";
  } else {
    comparison = "is equal to";
    symbol = "=";
  }

  useEffect(() => {
    onCompare?.({ value1, value2, comparison });
  }, [value1, value2]);

  return (
    <div className="flex items-center justify-center space-x-4 text-xl font-semibold text-gray-700 mt-4">
      <span>{fraction1.numerator}/{fraction1.denominator}</span>
      <span className="text-violet-600">{symbol}</span>
      <span>{fraction2.numerator}/{fraction2.denominator}</span>
    </div>
  );
};

const DecimalToFractionConverter = ({ trackConversion }) => {
  const [decimal, setDecimal] = useState("");

  const convertDecimalToFraction = (decimal) => {
    if (!decimal) return { numerator: 0, denominator: 1 };

    const startTime = performance.now();
    const precision = 1000000;
    let numerator = Math.round(parseFloat(decimal) * precision);
    let denominator = precision;

    const gcd = (a, b) => b ? gcd(b, a % b) : a;
    const divisor = gcd(numerator, denominator);

    const result = {
      numerator: numerator / divisor,
      denominator: denominator / divisor
    };

    trackConversion?.({
      type: 'decimal_to_fraction',
      input: decimal,
      result: `${result.numerator}/${result.denominator}`,
      conversionTime: performance.now() - startTime
    });

    return result;
  };

  const fraction = convertDecimalToFraction(decimal);

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Decimal Number</label>
        <input
          type="number"
          value={decimal}
          onChange={(e) => setDecimal(e.target.value)}
          className="w-full px-3 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
          placeholder="Enter decimal number"
          step="any"
        />
      </div>
      {decimal && (
        <ResultDisplay
          label="Fraction"
          fraction={fraction}
          decimal={parseFloat(decimal).toFixed(6)}
        />
      )}
    </div>
  );
};

const PercentageToFractionConverter = ({ trackConversion }) => {
  const [percentage, setPercentage] = useState("");

  const convertPercentageToFraction = (percentage) => {
    if (!percentage) return { numerator: 0, denominator: 1 };

    const startTime = performance.now();
    const decimal = parseFloat(percentage) / 100;
    const precision = 1000000;
    let numerator = Math.round(decimal * precision);
    let denominator = precision;

    const gcd = (a, b) => b ? gcd(b, a % b) : a;
    const divisor = gcd(numerator, denominator);

    const result = {
      numerator: numerator / divisor,
      denominator: denominator / divisor
    };

    trackConversion?.({
      type: 'percentage_to_fraction',
      input: percentage,
      result: `${result.numerator}/${result.denominator}`,
      conversionTime: performance.now() - startTime
    });

    return result;
  };

  const fraction = convertPercentageToFraction(percentage);

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Percentage</label>
        <div className="relative">
          <input
            type="number"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            className="w-full px-3 py-2 pr-8 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
            placeholder="Enter percentage"
          />
          <span className="absolute right-3 top-2 text-gray-500">%</span>
        </div>
      </div>
      {percentage && (
        <ResultDisplay
          label="Fraction"
          fraction={fraction}
          decimal={(parseFloat(percentage) / 100).toFixed(6)}
        />
      )}
    </div>
  );
};

const OperationButton = ({ selected, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg font-medium transition-colors ${
      selected ? 'bg-violet-600 text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
    }`}
  >
    {children}
  </button>
);

const FractionCalculatorScreen = () => {
  const [fraction1, setFraction1] = useState({ numerator: '', denominator: '1' });
  const [fraction2, setFraction2] = useState({ numerator: '', denominator: '1' });
  const [operation, setOperation] = useState('+');
  const [mixedNumber, setMixedNumber] = useState({ whole: '', numerator: '', denominator: '1' });
  const [calculationStartTime, setCalculationStartTime] = useState(null);

  const { 
    trackCalculation, 
    trackError, 
    trackInteraction,
    trackFeatureUsage 
  } = useAnalytics();

  // Track initial load
  useEffect(() => {
    trackFeatureUsage('fraction_calculator', 'open', {
      timestamp: new Date().toISOString()
    });

    return () => {
      trackFeatureUsage('fraction_calculator', 'close', {
        duration: Date.now() - calculationStartTime
      });
    };
  }, []);

  const handleFractionInput = (value, isFirstFraction) => {
    trackInteraction({
      eventType: 'fraction_input',
      elementId: isFirstFraction ? 'fraction1' : 'fraction2',
      metadata: {
        value,
        currentOperation: operation
      }
    });

    if (isFirstFraction) {
      setFraction1(value);
    } else {
      setFraction2(value);
    }
  };

  const handleOperationChange = (newOperation) => {
    const startTime = performance.now();
    trackInteraction({
      eventType: 'operation_change',
      elementId: `operation_${newOperation}`,
      metadata: {
        previousOperation: operation,
        fraction1,
        fraction2
      }
    });

    setOperation(newOperation);
    setCalculationStartTime(startTime);
  };

  const handleConversion = (conversionData) => {
    trackCalculation({
      calculatorType: 'fraction',
      operation: conversionData.type,
      inputValue1: conversionData.input,
      result: conversionData.result,
      calculationTime: conversionData.conversionTime
    });
  };

  const handleComparisonResult = (result) => {
    trackCalculation({
      calculatorType: 'fraction',
      operation: 'comparison',
      inputValue1: `${fraction1.numerator}/${fraction1.denominator}`,
      inputValue2: `${fraction2.numerator}/${fraction2.denominator}`,
      result: result.comparison
    });
  };

  const calculateFractions = () => {
    try {
      if (!fraction1.numerator || !fraction2.numerator) return null;

      const startTime = performance.now();
      const num1 = parseInt(fraction1.numerator);
      const den1 = parseInt(fraction1.denominator);
      const num2 = parseInt(fraction2.numerator);
      const den2 = parseInt(fraction2.denominator);

      if (den1 === 0 || den2 === 0) {
        throw new Error('Division by zero');
      }

      let resultNum, resultDen;

      switch (operation) {
        case '+': {
          const lcm = (a, b) => Math.abs(a * b) / gcd(a, b);
          const commonDen = lcm(den1, den2);
          resultNum = (num1 * (commonDen / den1)) + (num2 * (commonDen / den2));
          resultDen = commonDen;
          break;
        }
        case '-': {
          const lcm = (a, b) => Math.abs(a * b) / gcd(a, b);
          const commonDen = lcm(den1, den2);
          resultNum = (num1 * (commonDen / den1)) - (num2 * (commonDen / den2));
          resultDen = commonDen;
          break;
        }
        case '×': {
          resultNum = num1 * num2;
          resultDen = den1 * den2;
          break;
        }
        case '÷': {
          if (num2 === 0) throw new Error('Division by zero');
          resultNum = num1 * den2;
          resultDen = den1 * num2;
          break;
        }
        default:
          throw new Error('Invalid operation');
      }

      const gcd = (a, b) => b ? gcd(b, a % b) : Math.abs(a);
      const divisor = gcd(resultNum, resultDen);
      const result = {
        numerator: resultNum / divisor,
        denominator: resultDen / divisor,
        decimal: (resultNum / resultDen).toFixed(3)
      };

      trackCalculation({
        calculatorType: 'fraction',
        operation,
        inputValue1: `${num1}/${den1}`,
        inputValue2: `${num2}/${den2}`,
        result: `${result.numerator}/${result.denominator}`,
        calculationTime: performance.now() - startTime
      });

      return result;
    } catch (error) {
      trackError('fraction', 'calculation_error', error.message, {
        fraction1,
        fraction2,
        operation
      });
      return null;
    }
  };

  const result = calculateFractions();

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gray-50 py-8 px-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Fraction Calculator</h1>
        <div className="grid gap-6 md:grid-cols-2">
          <CalculatorCard
            title="Fraction Operations"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>}
          >
            <div className="space-y-4">
              <FractionInput
                label="First Fraction"
                value={fraction1}
                onChange={(val) => handleFractionInput(val, true)}
                trackInput={(val, part) => trackInteraction({
                  eventType: 'fraction_input',
                  elementId: `fraction1_${part}`,
                  metadata: { value: val[part] }
                })}
              />
              <div className="flex justify-center space-x-2">
                {['+', '-', '×', '÷'].map(op => (
                  <OperationButton
                    key={op}
                    selected={operation === op}
                    onClick={() => handleOperationChange(op)}
                  >
                    {op}
                  </OperationButton>
                ))}
              </div>
              <FractionInput
                label="Second Fraction"
                value={fraction2}
                onChange={(val) => handleFractionInput(val, false)}
                trackInput={(val, part) => trackInteraction({
                  eventType: 'fraction_input',
                  elementId: `fraction2_${part}`,
                  metadata: { value: val[part] }
                })}
              />
              {result && (
                <ResultDisplay
                  label="Result"
                  fraction={result}
                  decimal={result.decimal}
                />
              )}
            </div>
          </CalculatorCard>

          {/* Mixed Number Converter */}
          <CalculatorCard
            title="Mixed Number Converter"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
            </svg>}
          >
            <div className="space-y-4">
              <div className="flex items-end space-x-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Whole Number</label>
                  <input
                    type="number"
                    value={mixedNumber.whole}
                    onChange={(e) => {
                      const newValue = { ...mixedNumber, whole: e.target.value };
                      setMixedNumber(newValue);
                      trackInteraction({
                        eventType: 'mixed_number_input',
                        elementId: 'whole_number',
                        metadata: { value: e.target.value }
                      });
                    }}
                    className="w-20 px-3 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-transparent"
                    placeholder="0"
                  />
                </div>
                <FractionInput
                  label="Fraction Part"
                  value={{ numerator: mixedNumber.numerator, denominator: mixedNumber.denominator }}
                  onChange={(val) => {
                    const newValue = { ...mixedNumber, numerator: val.numerator, denominator: val.denominator };
                    setMixedNumber(newValue);
                    trackInteraction({
                      eventType: 'mixed_number_fraction_input',
                      elementId: 'fraction_part',
                      metadata: { value: val }
                    });
                  }}
                />
              </div>
              <ResultDisplay
                label="Improper Fraction"
                fraction={(() => {
                  try {
                    const startTime = performance.now();
                    const whole = parseInt(mixedNumber.whole) || 0;
                    const num = parseInt(mixedNumber.numerator) || 0;
                    const den = parseInt(mixedNumber.denominator) || 1;
                    
                    if (den === 0) throw new Error('Division by zero');
                    
                    const improperNum = Math.abs(whole) * den + num;
                    const result = {
                      numerator: whole >= 0 ? improperNum : -improperNum,
                      denominator: den,
                      decimal: ((whole * den + num) / den).toFixed(3)
                    };

                    trackCalculation({
                      calculatorType: 'fraction',
                      operation: 'mixed_to_improper',
                      inputValue1: `${whole} ${num}/${den}`,
                      result: `${result.numerator}/${result.denominator}`,
                      calculationTime: performance.now() - startTime
                    });

                    return result;
                  } catch (error) {
                    trackError('fraction', 'mixed_number_conversion_error', error.message, { mixedNumber });
                    return null;
                  }
                })()}
              />
            </div>
          </CalculatorCard>

          {/* Fraction Comparison */}
          <CalculatorCard
            title="Compare Fractions"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
            </svg>}
          >
            <div className="space-y-4">
              <FractionInput
                label="First Fraction"
                value={fraction1}
                onChange={(val) => handleFractionInput(val, true)}
                trackInput={(val, part) => trackInteraction({
                  eventType: 'comparison_input',
                  elementId: `compare1_${part}`,
                  metadata: { value: val[part] }
                })}
              />
              <FractionInput
                label="Second Fraction"
                value={fraction2}
                onChange={(val) => handleFractionInput(val, false)}
                trackInput={(val, part) => trackInteraction({
                  eventType: 'comparison_input',
                  elementId: `compare2_${part}`,
                  metadata: { value: val[part] }
                })}
              />
              {fraction1.denominator !== '0' && fraction2.denominator !== '0' && (
                <ComparisonDisplay
                  fraction1={fraction1}
                  fraction2={fraction2}
                  onCompare={handleComparisonResult}
                />
              )}
            </div>
          </CalculatorCard>

          {/* Decimal to Fraction */}
          <CalculatorCard
            title="Decimal to Fraction"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>}
          >
            <DecimalToFractionConverter trackConversion={handleConversion} />
          </CalculatorCard>

          {/* Percentage to Fraction */}
          <CalculatorCard
            title="Percentage to Fraction"
            icon={<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>}
          >
            <PercentageToFractionConverter trackConversion={handleConversion} />
          </CalculatorCard>
        </div>
      </div>
    </div>
  );
};

export default FractionCalculatorScreen;
// src/components/CalculatorButton.js
import React from 'react';

const CalculatorButton = ({ label, onClick, variant = 'number', className = '', fullWidth = false }) => {
  const baseClass = 'h-16 text-lg font-medium transition-all duration-200 rounded-2xl flex items-center justify-center';
  
  const variantClasses = {
    number: 'bg-white hover:bg-gray-50 text-gray-800 shadow-sm',
    operator: 'bg-violet-500 hover:bg-violet-600 text-white',
    function: 'bg-gray-200 hover:bg-gray-300 text-gray-700',
    active: 'bg-violet-100 text-violet-700 hover:bg-violet-200'
  };

  return (
    <button
      onClick={onClick}
      className={`
        ${baseClass}
        ${variantClasses[variant]}
        ${fullWidth ? 'col-span-2' : ''}
        ${className}
      `}
    >
      {label}
    </button>
  );
};

export default CalculatorButton;
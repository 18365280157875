// src/screens/Financial/MortgageCalculatorScreen.js
import React, { useState, useEffect } from 'react';
import {
  CalculatorCard,
  InputField,
  ResultCard,
  AmortizationTable,
  PaymentChart,
  CalculatorIcons,
  formatCurrency,
  formatPercentage
} from '../../components/FinancialCalculatorComponents';
import { Collapsible } from '../../components/Collapsible';


const MortgageCalculatorScreen = () => {
  const [loanAmount, setLoanAmount] = useState('300000');
  const [interestRate, setInterestRate] = useState('3.5');
  const [loanTerm, setLoanTerm] = useState('30');
  const [downPayment, setDownPayment] = useState('60000');
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [chartData, setChartData] = useState([]);

  const calculateMortgage = () => {
    const principal = parseFloat(loanAmount) - parseFloat(downPayment);
    const rate = parseFloat(interestRate) / 100 / 12;
    const payments = parseFloat(loanTerm) * 12;

    if (principal > 0 && rate > 0 && payments > 0) {
      const x = Math.pow(1 + rate, payments);
      const monthly = (principal * x * rate) / (x - 1);

      setMonthlyPayment(monthly);
      setTotalPayment(monthly * payments);
      setTotalInterest((monthly * payments) - principal);

      // Calculate amortization schedule
      let balance = principal;
      const schedule = [];
      const yearlyData = [];
      let yearlyPrincipal = 0;
      let yearlyInterest = 0;

      for (let i = 1; i <= payments; i++) {
        const interest = balance * rate;
        const principalPaid = monthly - interest;
        balance -= principalPaid;

        yearlyPrincipal += principalPaid;
        yearlyInterest += interest;

        if (i % 12 === 0 || i === payments) {
          yearlyData.push({
            year: Math.ceil(i / 12),
            principal: yearlyPrincipal,
            interest: yearlyInterest
          });
          yearlyPrincipal = 0;
          yearlyInterest = 0;
        }

        schedule.push({
          month: i,
          payment: monthly,
          principal: principalPaid,
          interest: interest,
          balance: Math.max(0, balance)
        });
      }

      setAmortizationSchedule(schedule);
      setChartData(yearlyData);
    }
  };

  useEffect(() => {
    calculateMortgage();
  }, [loanAmount, interestRate, loanTerm, downPayment]);

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gray-50 py-4 md:py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-6">
          Mortgage Calculator
        </h1>

        <div className="grid gap-4 md:gap-6 lg:grid-cols-3">
          <div className="lg:col-span-1 space-y-4 md:space-y-6">
            <CalculatorCard title="Loan Details" icon={<CalculatorIcons.Money />}>
              <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
                <InputField
                  label="Home Price"
                  value={loanAmount}
                  onChange={setLoanAmount}
                  prefix="$"
                  type="number"
                  step="1000"
                />
                <InputField
                  label="Down Payment"
                  value={downPayment}
                  onChange={setDownPayment}
                  prefix="$"
                  type="number"
                  step="1000"
                  info={formatPercentage((parseFloat(downPayment) / parseFloat(loanAmount)) * 100)}
                />
                <InputField
                  label="Interest Rate"
                  value={interestRate}
                  onChange={setInterestRate}
                  suffix="%"
                  type="number"
                  step="0.1"
                />
                <InputField
                  label="Loan Term"
                  value={loanTerm}
                  onChange={setLoanTerm}
                  suffix="years"
                  type="number"
                  step="1"
                />
              </div>
            </CalculatorCard>

            <CalculatorCard title="Monthly Breakdown" icon={<CalculatorIcons.Money />}>
              <div className="space-y-4">
                <ResultCard
                  label="Monthly Payment"
                  value={formatCurrency(monthlyPayment)}
                  description="Principal + Interest"
                />
                <ResultCard
                  label="Total Interest"
                  value={formatCurrency(totalInterest)}
                  color="rose"
// src/screens/Financial/MortgageCalculatorScreen.js (continued)
description={`${formatPercentage((totalInterest / totalPayment) * 100)} of total payment`}
                />
                <ResultCard
                  label="Total Payment"
                  value={formatCurrency(totalPayment)}
                  color="emerald"
                  description={`Over ${loanTerm} years`}
                />
              </div>
            </CalculatorCard>
          </div>

          <div className="lg:col-span-2 space-y-4 md:space-y-6">
                <CalculatorCard 
                    title="Payment Breakdown" 
                    icon={<CalculatorIcons.Chart />}
                >
                    <div className="space-y-4">
                    <div className="text-sm text-gray-500">
                        See how your payments are split between principal and interest
                    </div>
                    <PaymentChart data={chartData} />
                    </div>
                </CalculatorCard>

                <CalculatorCard 
                    title="Payment Schedule" 
                    icon={<CalculatorIcons.Table />}
                >
                    <Collapsible 
                    title="View Detailed Amortization Schedule" 
                    className="border border-gray-200 rounded-lg"
                    >
                    <div className="overflow-hidden">
                        <div className="text-sm text-gray-500 mb-4">
                        Yearly breakdown of your mortgage payments
                        </div>
                        <AmortizationTable 
                        schedule={amortizationSchedule.filter((_, i) => i % 12 === 0)}
                        type="mortgage"  // Add this prop
                        />
                    </div>
                    </Collapsible>

                    {/* Add a summary of first and last years */}
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm font-medium text-gray-700 mb-2">First Year</div>
                        <div className="space-y-1">
                        <div className="text-sm text-gray-600">
                            Principal: {formatCurrency(amortizationSchedule[11]?.principal || 0)}
                        </div>
                        <div className="text-sm text-gray-600">
                            Interest: {formatCurrency(amortizationSchedule[11]?.interest || 0)}
                        </div>
                        </div>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm font-medium text-gray-700 mb-2">Last Year</div>
                        <div className="space-y-1">
                        <div className="text-sm text-gray-600">
                            Principal: {formatCurrency(amortizationSchedule[amortizationSchedule.length - 1]?.principal || 0)}
                        </div>
                        <div className="text-sm text-gray-600">
                            Interest: {formatCurrency(amortizationSchedule[amortizationSchedule.length - 1]?.interest || 0)}
                        </div>
                        </div>
                    </div>
                    </div>
                </CalculatorCard>
                </div>
        </div>

        <div className="mt-6">
            <Collapsible 
                title="About this calculator" 
                className="bg-blue-50 rounded-lg border border-blue-100"
            >
                <div className="px-4 pb-4">
                <p className="text-sm text-blue-600">
                    This mortgage calculator helps you estimate your monthly mortgage payments. 
                    It provides a full amortization schedule and breaks down your payments between principal and interest. 
                    Please note that this is a basic calculation and actual mortgage payments may vary. Additional costs 
                    like property taxes, insurance, and HOA fees are not included.
                </p>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-white bg-opacity-50 rounded p-3">
                    <h4 className="text-sm font-medium text-blue-800 mb-2">What's included:</h4>
                    <ul className="text-sm text-blue-600 space-y-1">
                        <li>• Monthly principal and interest payments</li>
                        <li>• Total interest over loan term</li>
                        <li>• Amortization schedule</li>
                        <li>• Payment breakdown visualization</li>
                    </ul>
                    </div>
                    <div className="bg-white bg-opacity-50 rounded p-3">
                    <h4 className="text-sm font-medium text-blue-800 mb-2">Not included:</h4>
                    <ul className="text-sm text-blue-600 space-y-1">
                        <li>• Property taxes</li>
                        <li>• Home insurance</li>
                        <li>• HOA fees</li>
                        <li>• Private mortgage insurance (PMI)</li>
                    </ul>
                    </div>
                </div>
                </div>
            </Collapsible>
            </div>
      </div>
    </div>
  );
};

export default MortgageCalculatorScreen;
// src/screens/Math/ScientificCalculatorScreen.js
import React, { useState } from 'react';
import CalculatorButton from '../../components/CalculatorButton';
import CalculatorDisplay from '../../components/CalculatorDisplay';
import { 
  formatNumber, 
  calculate, 
  calculateScientific, 
  toScientificNotation, 
  MATHEMATICAL_CONSTANTS 
} from '../../utils/mathFunctions';

const ScientificCalculatorScreen = () => {
  const [display, setDisplay] = useState('0');
  const [equation, setEquation] = useState('');
  const [prevNumber, setPrevNumber] = useState(null);
  const [operation, setOperation] = useState(null);
  const [resetDisplay, setResetDisplay] = useState(false);
  const [isRadians, setIsRadians] = useState(true);
  const [isInverse, setIsInverse] = useState(false);
  const [isShift, setIsShift] = useState(false);
  const [memory, setMemory] = useState(0);

  const handleNumber = (num) => {
    if (resetDisplay || display === '0') {
      setDisplay(num);
      setResetDisplay(false);
    } else {
      setDisplay(display + num);
    }
  };

  const handleOperation = (op) => {
    setOperation(op);
    setPrevNumber(parseFloat(display));
    setResetDisplay(true);
    setEquation(`${display} ${op}`);
  };

  const handleEqual = () => {
    if (!operation || !prevNumber) return;

    const result = calculate(prevNumber, display, operation);
    setDisplay(formatNumber(result));
    setEquation(`${equation} ${display} =`);
    setOperation(null);
    setPrevNumber(null);
    setResetDisplay(true);
  };

  const handleClear = () => {
    setDisplay('0');
    setEquation('');
    setPrevNumber(null);
    setOperation(null);
    setResetDisplay(false);
  };

  const handleMemory = (action) => {
    const currentValue = parseFloat(display);
    switch (action) {
      case 'MC':
        setMemory(0);
        break;
      case 'MR':
        setDisplay(memory.toString());
        setResetDisplay(true);
        break;
      case 'M+':
        setMemory(memory + currentValue);
        setResetDisplay(true);
        break;
      case 'M-':
        setMemory(memory - currentValue);
        setResetDisplay(true);
        break;
      case 'MS':
        setMemory(currentValue);
        setResetDisplay(true);
        break;
      default:
        break;
    }
  };

  const handleScientificFunction = (func) => {
    const num = parseFloat(display);
    let result;
    
    if (['π', 'e'].includes(func)) {
      result = MATHEMATICAL_CONSTANTS[func === 'π' ? 'PI' : 'E'];
    } else {
      result = calculateScientific(num, func, isInverse, isRadians);
    }

    const formattedResult = Math.abs(result) > 1e10 || Math.abs(result) < 1e-10 
      ? toScientificNotation(result)
      : formatNumber(result);

    setDisplay(formattedResult);
    setEquation(`${func}(${display})`);
    setResetDisplay(true);
  };

  // Enhanced scientific calculator buttons with more functions
  const scientificButtons = [
    // Row 1 - Memory and Mode Controls
    [
      { label: 'MC', variant: 'function', onClick: () => handleMemory('MC') },
      { label: 'MR', variant: 'function', onClick: () => handleMemory('MR') },
      { label: 'M+', variant: 'function', onClick: () => handleMemory('M+') },
      { label: 'M-', variant: 'function', onClick: () => handleMemory('M-') },
      { label: 'MS', variant: 'function', onClick: () => handleMemory('MS') },
    ],
    // Row 2 - Mode Controls
    [
      { label: 'Rad', variant: isRadians ? 'active' : 'function', onClick: () => setIsRadians(true) },
      { label: 'Deg', variant: !isRadians ? 'active' : 'function', onClick: () => setIsRadians(false) },
      { label: 'Inv', variant: isInverse ? 'active' : 'function', onClick: () => setIsInverse(!isInverse) },
      { label: '2nd', variant: isShift ? 'active' : 'function', onClick: () => setIsShift(!isShift) },
      { label: 'C', variant: 'function', onClick: handleClear },
    ],
    // Row 3 - Advanced Functions
    [
      { label: isInverse ? 'sin⁻¹' : 'sin', variant: 'function', onClick: () => handleScientificFunction('sin') },
      { label: isInverse ? 'cos⁻¹' : 'cos', variant: 'function', onClick: () => handleScientificFunction('cos') },
      { label: isInverse ? 'tan⁻¹' : 'tan', variant: 'function', onClick: () => handleScientificFunction('tan') },
      { label: 'π', variant: 'function', onClick: () => handleScientificFunction('π') },
      { label: 'e', variant: 'function', onClick: () => handleScientificFunction('e') },
    ],
    // Row 4 - More Functions
    [
      { label: isShift ? 'x³' : 'x²', variant: 'function', onClick: () => handleScientificFunction(isShift ? 'x³' : 'x²') },
      { label: isShift ? '∛' : '√', variant: 'function', onClick: () => handleScientificFunction(isShift ? 'cbrt' : 'sqrt') },
      { label: isShift ? '10^x' : 'log', variant: 'function', onClick: () => handleScientificFunction('log') },
      { label: isShift ? 'eˣ' : 'ln', variant: 'function', onClick: () => handleScientificFunction('ln') },
      { label: '1/x', variant: 'function', onClick: () => handleScientificFunction('1/x') },
    ],
    // Row 5 - Numbers and Operations Row 1
    [
      { label: isShift ? 'sinh' : '|x|', variant: 'function', onClick: () => handleScientificFunction(isShift ? 'sinh' : 'abs') },
      { label: '7', onClick: () => handleNumber('7') },
      { label: '8', onClick: () => handleNumber('8') },
      { label: '9', onClick: () => handleNumber('9') },
      { label: '÷', variant: 'operator', onClick: () => handleOperation('÷') },
    ],
    // Row 6
    [
      { label: isShift ? 'cosh' : '⌊x⌋', variant: 'function', onClick: () => handleScientificFunction(isShift ? 'cosh' : 'floor') },
      { label: '4', onClick: () => handleNumber('4') },
      { label: '5', onClick: () => handleNumber('5') },
      { label: '6', onClick: () => handleNumber('6') },
      { label: '×', variant: 'operator', onClick: () => handleOperation('×') },
    ],
    // Row 7
    [
      { label: isShift ? 'tanh' : '⌈x⌉', variant: 'function', onClick: () => handleScientificFunction(isShift ? 'tanh' : 'ceil') },
      { label: '1', onClick: () => handleNumber('1') },
      { label: '2', onClick: () => handleNumber('2') },
      { label: '3', onClick: () => handleNumber('3') },
      { label: '-', variant: 'operator', onClick: () => handleOperation('-') },
    ],
    // Row 8
    [
      { label: 'n!', variant: 'function', onClick: () => handleScientificFunction('fact') },
      { label: '0', onClick: () => handleNumber('0') },
      { label: '.', onClick: () => !display.includes('.') && handleNumber('.') },
      { label: '=', variant: 'operator', onClick: handleEqual },
      { label: '+', variant: 'operator', onClick: () => handleOperation('+') },
    ],
  ];

  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-4rem)] bg-gray-50">
      <div className="w-full max-w-xl mx-auto p-6">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">Scientific Calculator</h1>
        <div className="bg-white rounded-3xl shadow-xl p-6">
          <CalculatorDisplay equation={equation} result={display} />
          <div className="grid gap-3">
            {scientificButtons.map((row, rowIndex) => (
              <div key={rowIndex} className="grid grid-cols-5 gap-3">
                {row.map((btn, btnIndex) => (
                  <CalculatorButton
                    key={`${rowIndex}-${btnIndex}`}
                    label={btn.label}
                    onClick={btn.onClick}
                    variant={btn.variant}
                    fullWidth={btn.fullWidth}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScientificCalculatorScreen;
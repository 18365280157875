// src/components/Layout/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ChevronIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6"/>
  </svg>
);

const NavDropdown = ({ title, items, isOpen, onClick, isMobile }) => {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname.startsWith(path) ? 'bg-violet-100 text-violet-700' : 'text-gray-600 hover:bg-violet-50';
  };

  if (isMobile) {
    return (
      <div className="px-2">
        <div className="py-2">
          <div className="text-sm font-medium text-gray-500 py-2">{title}</div>
          {items.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`${
                location.pathname === item.path 
                  ? 'bg-violet-100 text-violet-700' 
                  : 'text-gray-700 hover:bg-gray-50'
              } block px-3 py-2 rounded-md text-base font-medium mb-1`}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <button
        onClick={onClick}
        className={`${
          isOpen ? 'bg-gray-100' : ''
        } flex items-center px-4 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
      >
        {title}
        <ChevronIcon
          className={`ml-1 h-4 w-4 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      
      {isOpen && (
        <div className="absolute z-10 left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {items.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`${isActive(item.path)} block px-4 py-2 text-sm hover:bg-gray-50`}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mathItems = [
    { label: 'Basic Calculator', path: '/math/basic' },
    { label: 'Scientific Calculator', path: '/math/scientific' },
    { label: 'Fraction Calculator', path: '/math/fraction' },
    { label: 'Percentage Calculator', path: '/math/percentage' },
    { label: 'Quadratic Calculator', path: '/math/quadratic' },

  ];

  const financialItems = [
    { label: 'Mortgage Calculator', path: '/financial/mortgage' },
    { label: 'Loan Calculator', path: '/financial/loan' },
  ];

  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = () => {
      setActiveDropdown(null);
      setIsMobileMenuOpen(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <h1 className="text-xl font-bold text-violet-600">Real Calculator</h1>
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
              <div onClick={(e) => e.stopPropagation()}>
                <NavDropdown
                  title="Math"
                  items={mathItems}
                  isOpen={activeDropdown === 'math'}
                  onClick={() => handleDropdownClick('math')}
                />
              </div>
              
              <div onClick={(e) => e.stopPropagation()}>
                <NavDropdown
                  title="Financial"
                  items={financialItems}
                  isOpen={activeDropdown === 'financial'}
                  onClick={() => handleDropdownClick('financial')}
                />
              </div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none"
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="sm:hidden" onClick={(e) => e.stopPropagation()}>
          <div className="pt-2 pb-3 bg-white border-t border-gray-200">
            <NavDropdown
              title="Math Calculators"
              items={mathItems}
              isMobile={true}
            />
            <div className="border-t border-gray-200 my-2" />
            <NavDropdown
              title="Financial Calculators"
              items={financialItems}
              isMobile={true}
            />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;